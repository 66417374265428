.card-btn {
  background-color: #fafafa;
  height: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  margin: 2px;
}

input, .form-text-field {
  width: 90%;
  margin:  auto;
}

.form-text-field > textarea {
  width: 90%;
  margin-left: -10%;
}

.col-form-label {
  width: 100%;
}

.date-picker {
  width: 11rem;
  margin:  auto;
}

.required-alert {
  width: 90%;
  margin: auto;
  font-size: small;
  margin-bottom: 1px;
  height: 30px;
  line-height:30px;
  padding:0px 15px;
}

.form-group.required .col-form-label:after {
  content: " *";
}

.float-right {
  float: right;
}

.float-left {
  float:  left;
}

.photo-upload {
  position: relative;
  overflow: hidden;
  margin: 1rem;
}

.photo-upload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 2rem;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.photo-title > textarea, .photo-preview {
  width: 100%;
}

.margin-top-3 {
  margin-top: 3rem;
}