@import url(https://fonts.googleapis.com/css?family=Poppins);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  width:  100%;
  height: auto;
  max-width: 300px;
  background-color: #f4723e;
}

.App-header {
  height: 150px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.content {
  width: 80%;
  margin-left: 10%;
  text-align: center;
  align-content: center;
}

.small-content {
  width: 50%;
  margin-left: 25%;
  text-align: center;
  align-content: center;
}

.header {
  font-weight: bold;
  text-transform: uppercase;
}

form {
  margin-top: 1em;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

a {
  color: #92badd;
  display:inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  /* text-align: center; */
  font-size: 16px;
  font-weight: 600;
  
  display:inline-block;
  /* margin: 40px 8px 10px 8px;  */
  color: #cccccc;
}



/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column; 
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  border-radius: 0 0 10px 10px;
}



/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  
}



/* FORM TYPOGRAPHY*/

input[type=button], input[type=submit], input[type=reset]  {
  background-color: #3583aa;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  transition: all 0.3s ease-in-out;
}

input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover  {
  background-color: #39ace7;
  cursor: pointer;
}

input[type=button]:active, input[type=submit]:active, input[type=reset]:active  {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

input[type=text] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  transition: all 0.5s ease-in-out;
  border-radius: 5px 5px 5px 5px;
}

input[type=text]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type=text]:placeholder {
  color: #cccccc;
}

/* input type email */
input[type=email] {
	background-color: #f6f6f6;
	border: none;
	color: #0d0d0d;
	padding: 15px 32px;
	
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 5px;
	width: 85%;
	border: 2px solid #f6f6f6;
	transition: all 0.5s ease-in-out;
	border-radius: 5px 5px 5px 5px;
  }
  
  input[type=email]:focus {
	background-color: #fff;
	border-bottom: 2px solid #5fbae9;
  }
  
  input[type=email]:placeholder {
	color: #cccccc;
  }

  /* input type password */
input[type=password] {
	background-color: #f6f6f6;
	border: none;
	color: #0d0d0d;
	padding: 15px 32px;
	
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 5px;
	width: 85%;
	border: 2px solid #f6f6f6;
	transition: all 0.5s ease-in-out;
	border-radius: 5px 5px 5px 5px;
  }
  
  input[type=password]:focus {
	background-color: #fff;
	border-bottom: 2px solid #5fbae9;
  }
  
  input[type=password]:placeholder {
	color: #cccccc;
  }
  

.loginalert
{
  color: red;
}

.center{
  
  text-align: center;
  font-size: 80px;
  color: white;
  background: #3583aa !important
  }

  .footer-center
  {
    text-align: center;
  }



/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fadeIn {
  opacity:0;
  -webkit-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;

  -webkit-animation-fill-mode:forwards;
  animation-fill-mode:forwards;

  -webkit-animation-duration:1s;
  animation-duration:1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after{
  width: 100%;
}



/* OTHERS */

*:focus {
    outline: none;
} 

#icon {
  width:60%;
}

* {
  box-sizing: border-box;
}
.orange {
	background-color: #f4723e;
	display: inline-block;
	width: 100%;
	margin: auto;
}

.navi {
    display:inline-block;
    margin-top: -1rem;
    margin-bottom: 1.5rem;
}

.navi li {
	list-style: none;
	display:inline-block;
	margin:auto;
}

.navi li a {
	text-decoration: none;
	color: white;
	font-size: 1rem;
	padding: 1rem 0.5rem;
}

.navi li a:hover {
	color: #0056b3;
	border: darkmagenta;
	border-radius: 2px;
}

.navi li a:active {
	background-color: deepskyblue;
	border-radius: 5px;
}

.right {
	background-color: aqua;
	display: inline-block;
	width: 8%;
	font-size: 18px;
	padding: 18px 26px;
	color: #1d19e7;
	}

.right {
	background-color: aqua;
	display: inline-block;
	width: 8%;
	font-size: 18px;
	padding: 18px 26px;
	color: #1d19e7;
}

.right a {
	text-decoration:none;
}

.right:hover {
	font-weight:bolder;
}

.left {
	background-color: aqua;
	display: inline-block;
	width: 17%;
	font-size: 18px;
	padding: 18px 17px;
	color: #1d19e7;
}

.left a {
	text-decoration:none;
}

.left:hover {
	font-weight:bolder;
}

.notify {
    background-color: #f4723e;
    padding: 1rem 1rem 0.1rem 1rem;
    text-align: center;
    color: white;
}

.danger {
    background-color: #f4723e;
}
.card-btn {
  background-color: #fafafa;
  height: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  margin: 2px;
}

input, .form-text-field {
  width: 90%;
  margin:  auto;
}

.form-text-field > textarea {
  width: 90%;
  margin-left: -10%;
}

.col-form-label {
  width: 100%;
}

.date-picker {
  width: 11rem;
  margin:  auto;
}

.required-alert {
  width: 90%;
  margin: auto;
  font-size: small;
  margin-bottom: 1px;
  height: 30px;
  line-height:30px;
  padding:0px 15px;
}

.form-group.required .col-form-label:after {
  content: " *";
}

.float-right {
  float: right;
}

.float-left {
  float:  left;
}

.photo-upload {
  position: relative;
  overflow: hidden;
  margin: 1rem;
}

.photo-upload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 2rem;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.photo-title > textarea, .photo-preview {
  width: 100%;
}

.margin-top-3 {
  margin-top: 3rem;
}
.player-details {
  margin-top: 10%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12);
}
.player-name p {
  font-size: 22px;
  line-height: 1.68;
}
.player-name p span {
  font-weight: 600;
  color: #0f81dc;
}
.player-name p:nth-child(2) span {
  font-weight: 600;
  color: #0f81dc;
  padding-left: 7%;
}
.dashboard {
  font-family: "Segoe UI Regular";
  margin-top: 8%;
}
.control {
  padding-left: 15%;
}
.control span:nth-child(2) {
  padding: 0 5px 0 5px;
  display: inline-block;
  font-size: 14px;
  line-height: 1.36;
  text-align: right;
  color: #686868;
}
.acc-brain-img {
  position: relative;
  width: 100%;
  height: 100%;
}

.acc-brain-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50%;
  margin-top: -50%;
}

.acc-card {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.acc-card div div:nth-child(2) {
  font-size: 16px;
  line-height: 1.31;
  color: #666666;
}
.acc-card-before::before {
  width: auto;
  height: 45px;
  content: "Cumulative Events";
  color: #fff;
  background: #0f81dc;
  top: -12%;
  left: 0;
  border-radius: 10px;
  position: absolute;
  z-index: -2;
  text-align: center;
  font-size: 18px;
  line-height: 1.35;
  padding: 3px 0 3px 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 0 10px 0 10px;
}
.acc-brain-load {
  height: 87%;
}

.head-acc-evnt-chart {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.head-acc-evnt-chart::after {
  width: auto;
  height: 45px;
  content: attr(data-descr);
  color: #fff;
  background: #0f81dc;
  top: -13%;
  left: 0;
  border-radius: 10px;
  position: absolute;
  z-index: -22;
  text-align: center;
  font-size: 18px;
  line-height: 1.35;
  padding: 3px 0 3px 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  white-space: pre;
  padding: 0 10px 0 10px;
}

select.select-gender {
  color: #9e9e9e;
}
.cumm {
  margin-top: 6%;
}

.acc-evnt {
  margin-top: 7%;
  margin-bottom: 6%;
}
.goto-top > div {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: #0f81dc;
  color: #fff;
  border-radius: 50%;
  box-shadow: 3px 0 10px -1px rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
.goto-top p {
  font-size: 14px;
  color: #0f81dc;
  line-height: 10px;
  position: absolute;
  top: 30px;
}
.head-acc-evnt-chart > div > div:nth-child(2) p {
  /* display: inline-block; */
  font-size: 14px;
  line-height: 1.36;
  text-align: center;
  color: #686868;
  padding-left: 25%;
}
.dark-mode-container {
  width: 90px;
  height: 70px;
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.14);
  position: fixed;
  top: calc(50% - 70px);
  right: -70px;
  border-left: 5px solid #0f81dc;
  border-radius: 10px;
  transition: right 2s cubic-bezier(0.075, 0.82, 0.165, 1);
  cursor: pointer;
  background: #fff;
}
.dark-mode-container:hover {
  right: -5px;
}
.dark-mode-container img {
  width: 50%;
  -webkit-animation: move 2s linear infinite;
          animation: move 2s linear infinite;
}
@-webkit-keyframes move {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes move {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.dark-mode-chooser {
  width: 85px;
  height: 120px;
  position: absolute;
  right: 100px;
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  background: #0f81dc;
}
.dark-mode-chooser div:not(:nth-child(3)) {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.14);
  -webkit-animation: hvr-pulse 2s linear infinite;
          animation: hvr-pulse 2s linear infinite;
}
.dark-mode-chooser div:nth-child(1):hover {
  -webkit-animation: none;
          animation: none;
}
.dark-mode-chooser div:nth-child(2):hover {
  -webkit-animation: none;
          animation: none;
}
.dark-mode-chooser div:nth-child(1) {
  margin-top: 10%;
  margin-left: 30%;
  background: #fff;
}

.dark-mode-chooser div:nth-child(2) {
  margin-top: 20%;
  margin-left: 30%;
  background: #232838;
}
.arrow-right {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #0f81dc;
  top: 50%;
  position: absolute;
  right: -12px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.dashboard-mock {
  border-radius: 10px;
  height: 405px;
  overflow-y: auto;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
}
.dashboard-mock::-webkit-scrollbar {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #0f81dc;
}
.dashboard-mock::-webkit-scrollbar-thumb {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: rgb(142, 210, 255);
  box-shadow: -3px 0 10px rgba(0, 0, 0, 0.12);
}

.organization-admin-pt-8 {
  margin-top: 8% !important;
}

.edit-team-btn {
  background: #0f81dc;
  padding: 5px 20px 5px 20px;
  color: #fff;
  border: none;
  margin-top: 10px;
  margin-right: 10px;
}
.edit-teams {
  background: rgba(0, 0, 0, 0.06);
  position: relative;
  margin: 5px;
  border-radius: 10px;
}
.delete__icon {
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
}
.modal__wrapper {
  position: fixed;
  z-index: 1;
  height: 100%;
  background: transparent;
  width: 100%;
  background: #205b912e;
}
div.modal_form {
  height: 580px !important;
  margin-top: -200px !important;
}

.edit-your-team {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 2px;
  border-bottom: 1px solid #fff;
  margin-bottom: 10px !important;
}

input.team-edit-input {
  background-color: transparent !important;
}
.team-form {
  margin-left: auto;
  margin-right: auto;
  padding-left: 10%;
  padding-right: 10%;
}
.modal__show {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: 0 !important;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  -webkit-animation: fadeIn 1s, slideIn 0.8s linear;
          animation: fadeIn 1s, slideIn 0.8s linear;
}
.action_buttons button {
  border: 1px solid #0f81dc;
  background: #fff;
  color: #0f81dc;
  padding: 5px 20px 5px 20px;
  border-radius: 10px;
  margin: 10px;
}

.military-version-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2% 20% 2% 20%;
  background: linear-gradient(45deg, #0000, #0b3b88, #0000);
  box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.2);
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }

  100% {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
}

@keyframes slideIn {
  0% {
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }

  100% {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.hover_edit {
  display: flex;
  align-items: center;
  justify-content: center;
  background: aliceblue;
}
.pen_icon {
  width: 50%;
  /* position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%) */
}
.ptf-team {
  padding: 0 4% 0 4%;
}
.align-element-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60.1vh;
}

.contact__page-center-align {
  padding-top: 5%;
}

input.fromTodate {
  border-bottom: none !important;
}
input.borderRight__fromTodate {
  border-right: 1px solid #0f81dc;
}
.militay-view {
  height: 100vh;
  width: 100%;
  display: flex;
}
.military-sidebar {
  height: 100vh;
  width: 15%;
  background: linear-gradient(0deg, #000, #1282d8, #000);
  box-shadow: 5px 0 10px -1px rgba(0, 0, 0, 0.12);
}
.military-main-content {
  width: 85%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  /* padding: 0 5% 0 5%; */
}
.military-dark-mode {
  background: #171b25;
}

div.penstate-university-bg {
  padding-top: 4%;
  padding-bottom: 4%;
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(8, 133, 233, 1) 0%,
    rgba(2, 34, 67, 1) 50%,
    rgba(1, 8, 25, 1) 100%
  );
}

.organization-pad__military {
  padding: 0 3% 0 3%;
}

.slidebar-nav {
  margin-top: 10vh;
  text-align: center;
}
.slidebar-nav h5 {
  font-size: 30px;
  font-weight: 900;
  background: #003a69;
  line-height: 50px;
  color: #fff;
}
.slidebar-nav ul {
  text-align: center;
}

.list-one > li:nth-child(1) {
  background: none;
  padding: 5px 0 5px 0;
  color: #fff;
  border-bottom: 1px solid #fff;
}

.list-one > li {
  padding: 5px;
}

.list-one > li a {
  color: #fff;
  text-decoration: none;
  padding: 5px 0 5px 0;
}
.list-two > li {
  padding: 5px 5px 5px 5px;
}
div.titles {
  flex-flow: column;
}

@media screen and (max-width: 599px) {
  .head-acc-evnt-chart::after {
    white-space: pre-wrap;
    top: -12%;
    height: 70px;
  }
  .acc-evnt {
    margin-top: 25%;
  }
  div.titiles input,
  input.upload-btn {
    width: 225px;
  }
  .acc-card-before::before {
    top: -6%;
  }
  .daily-btn,
  .weekly-btn,
  .monthly-btn {
    margin-right: 2px;
  }
  .export-report-btn {
    margin-top: 2px;
  }
  .width-100 {
    width: 100% !important;
  }
  .dashboard {
    overflow-x: hidden;
  }

  .dashboard-mock::before {
    content: "Please tap to close";
    color: #fff;
    font-size: 12px;
  }
  div.organization_team {
    width: 105%;
    height: 39vh !important;
  }
  div.t-roster {
    margin-top: 10vh;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  div.titiles input,
  input.upload-btn {
    width: 225px;
  }
  div.t-roster {
    margin-top: 10vh;
  }
}

@media (min-width: 768px) {
  .adminDashboardTop {
    display: inline-flex;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1500px) {
  div.penstate-university-bg {
    padding-top: 8% !important;
  }
}
@media screen and (min-width: 2560px) and (max-width: 3238px) {
  div.organization_team {
    bottom: -35.4vh;
  }
  div.profile-mt {
    height: 71.2vh;
    align-items: center;
    display: grid;
  }
  div.profile-container {
    position: relative;
  }
}

@media screen and (min-width: 1680px) and (max-width: 3239px) {
  div.t-roster {
    margin-top: 6% !important;
  }
}
@media screen and (min-width: 3240px) {
  div.t-roster {
    margin-top: 7% !important;
  }
  .align-element-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90.1vh;
  }
  .contact__page-center-align {
    padding-top: 5%;
    padding-bottom: 6.4%;
  }
  div.profile-mt {
    margin-top: 25%;
  }
  div.profile__page {
    bottom: -130.1%;
  }
  .align-center__about-page {
    margin-top: 21%;
  }
  div.about__page {
    bottom: -189%;
  }
  div.contact__page {
    bottom: -9.8vh !important;
  }
  div.pt-surface {
    padding-top: 287px;
  }
}
.brain-card-pt-2-5 {
  padding-top: 1.5rem !important;
}

@media (max-width: 768px) {
  .adminDashboardTop {
    display: block !important;
  }

  .brain-card-pt-2-5 {
    padding-top: 2.5rem !important;
  }
  .cumm {
    margin-top: 10%;
  }
  .player-dashboard-sub-head {
    text-align: center !important;
    align-items: center !important;
  }
  .sub-head-button {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
    margin-right: 0px !important;
    width: 100%;
    display: "block";
  }

  .player-dashboard-title {
    margin-bottom: 5% !important;
  }
}

.widget-subheading {
  font-weight: 300;
  color: #80878e;
  font-size: smaller;
  font-family: "Roboto", sans-serif;
}

.widget-numbers {
  font-weight: 800;
  text-decoration: overline;
  font-size: 1.4em;
  color: #0f81dc !important;
}

.chart-container {
  position: relative !important;
  height: 90px;
  width: 100% !important;
}

.player-dashboard-sub-head {
  padding-left: 5%;
  font-size: 1.4rem !important;
  font-weight: 900;
  font-family: "Segoe UI Regular";
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.94;
  letter-spacing: 3.53px;
  text-align: left;
  color: #686868;
}
.sub-head-button {
  margin-right: 5%;
}

