.orange {
	background-color: #f4723e;
	display: inline-block;
	width: 100%;
	margin: auto;
}

.navi {
    display:inline-block;
    margin-top: -1rem;
    margin-bottom: 1.5rem;
}

.navi li {
	list-style: none;
	display:inline-block;
	margin:auto;
}

.navi li a {
	text-decoration: none;
	color: white;
	font-size: 1rem;
	padding: 1rem 0.5rem;
}

.navi li a:hover {
	color: #0056b3;
	border: darkmagenta;
	border-radius: 2px;
}

.navi li a:active {
	background-color: deepskyblue;
	border-radius: 5px;
}

.right {
	background-color: aqua;
	display: inline-block;
	width: 8%;
	font-size: 18px;
	padding: 18px 26px;
	color: #1d19e7;
	}

.right {
	background-color: aqua;
	display: inline-block;
	width: 8%;
	font-size: 18px;
	padding: 18px 26px;
	color: #1d19e7;
}

.right a {
	text-decoration:none;
}

.right:hover {
	font-weight:bolder;
}

.left {
	background-color: aqua;
	display: inline-block;
	width: 17%;
	font-size: 18px;
	padding: 18px 17px;
	color: #1d19e7;
}

.left a {
	text-decoration:none;
}

.left:hover {
	font-weight:bolder;
}
