.App {
  text-align: center;
}

.App-logo {
  width:  100%;
  height: auto;
  max-width: 300px;
  background-color: #f4723e;
}

.App-header {
  height: 150px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.content {
  width: 80%;
  margin-left: 10%;
  text-align: center;
  align-content: center;
}

.small-content {
  width: 50%;
  margin-left: 25%;
  text-align: center;
  align-content: center;
}

.header {
  font-weight: bold;
  text-transform: uppercase;
}

form {
  margin-top: 1em;
}
